// React imports
import { useEffect, useState } from 'react'

// Remix imports
import { useLocation } from '@remix-run/react'

// Third party imports

// Generated imports

// App imports

const useLocationChange = (callbackFunc: () => void) => {
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState(location.pathname)
  useEffect(() => {
    if (location.pathname !== currentLocation) {
      setCurrentLocation(location.pathname)
      callbackFunc()
    }
  }, [currentLocation, location.pathname, callbackFunc])
}

export default useLocationChange
