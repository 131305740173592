// React imports
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Icon from '~/components/icons/Icon'
import useEscKey from '~/hooks/useEscKey'
import useGlobal from '~/hooks/useGlobal'
import useLocationChange from '~/hooks/useLocationChange'

// Remix imports

// Third party imports

// Generated imports

// App imports
import H1 from '~/components/typography/H1'
import IconCloseXSvgURL from '~/static/svg/IconCloseX.svg'
import { ModalsContext } from './Modals'

interface Props {
  clearWhenHide?: boolean
  contentClassName?: string
  children?: any
  size?: 'small' | 'medium' | 'large' | 'xlarge'
  hideModal?: boolean
  disabled?: boolean
  title?: string
  titleClassName?: string
  externalClose?: boolean
}

const Modal: React.FC<Props> = ({
  clearWhenHide,
  contentClassName,
  title,
  titleClassName,
  children,
  size,
  hideModal,
  disabled,
  externalClose = false,
}) => {
  const global = useGlobal()
  const modalsCtx = useContext(ModalsContext)
  const [isMounted, setIsMounted] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const dismiss = useCallback(() => {
    if (!disabled) {
      setIsMounted(false)
      if (modalsCtx) {
        setTimeout(() => {
          if (clearWhenHide) {
            modalsCtx.clear()
          } else {
            modalsCtx.hide()
          }
        }, 100)
      }
    }
  }, [disabled, modalsCtx, clearWhenHide])

  useEscKey(dismiss)
  useLocationChange(dismiss)

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true)
    }, 50)
  }, [])

  useEffect(() => {
    if (hideModal) {
      dismiss()
    }
  }, [hideModal, dismiss])

  // if externalClose, we want to close the modal when clicking outside of it
  useEffect(() => {
    if (externalClose) {
      const close = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
          dismiss()
        }
      }
      document.addEventListener('click', close)
      return () => {
        document.removeEventListener('click', close)
      }
    }
  }, [externalClose, dismiss, modalsCtx])

  if (!global) {
    return null
  }

  const trans = global.trans

  return (
    <div
      className={
        'fixed inset-0 z-50 h-full w-full overflow-y-auto bg-gray-600 bg-opacity-50 transition-all duration-500 ease-out md:px-3' +
        (isMounted ? ' opacity-100' : ' opacity-0')
      }
    >
      <div
        ref={ref}
        className={
          `relative bg-white p-[30px] transition-all duration-500 ease-out md:mx-auto md:mb-12` +
          (size === 'small' ? ` md:max-w-[550px]` : '') +
          (size === 'medium' ? ` md:max-w-[750px]` : '') +
          (size === 'large' ? ` md:max-w-[950px]` : '') +
          (size === 'xlarge' ? ` md:max-w-[1150px]` : '') +
          (isMounted ? ' md:mt-12' : ' md:mt-6')
        }
      >
        <div className="absolute right-[30px] top-[30px]">
          <Icon
            className="cursor-not-allowed"
            alt={trans.CloseThisModal}
            width="32px"
            onClick={() => {
              if (modalsCtx) {
                dismiss()
              }
            }}
            src={IconCloseXSvgURL}
            hover={true}
          />
        </div>

        <div className="mt-[32px]">
          {title && (
            <H1
              className={
                'mb-3 leading-[1.2em]' +
                (titleClassName ? ` ${titleClassName}` : '')
              }
            >
              {title}
            </H1>
          )}
          <div className={contentClassName}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Modal
